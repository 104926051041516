import React from 'react'

const IconEnvelop = ({
    style = {},
    fill = '#ffffff',
    width = '18',
    height = '18',
    viewBox = '0 0 18 18',
  }) => 
    (
        <svg className="iconEnvelop" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
            <path fill={fill} fill-rule="evenodd" d="M15.75 2.25c.828 0 1.5.672 1.5 1.5v10.5c0 .828-.672 1.5-1.5 1.5H2.25c-.828 0-1.5-.672-1.5-1.5V3.75c0-.828.672-1.5 1.5-1.5h13.5zm0 4.963L9 10.589 2.25 7.213v7.037h13.5V7.213zm0-3.463H2.25v1.786L9 8.912l6.75-3.376V3.75z"/>
        </svg>

    );

export default IconEnvelop