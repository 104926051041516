import React from 'react'
import emergence from 'emergence.js'

import Header from 'components/header'
import Player from 'components/player'
import Zone from 'components/zone'
import Footer from 'components/footer'
import Audio from 'components/audio'

import { siteMetadata } from '../../../gatsby-config'

import Zones from "../../../content/zones.json"

import 'modern-normalize/modern-normalize.css'
import 'prismjs/themes/prism.css'
import 'scss/gatstrap.scss'
import 'animate.css/animate.css'
import 'font-awesome/css/font-awesome.css'

class Layout extends React.Component {
  componentDidMount() {
    emergence.init()
  }

  componentDidUpdate() {
    emergence.init()
  }

  render() {
    
    const { children } = this.props

    return ( 
      <div>
        <Header />
        <Player />
        <div class="container">
          <div class="row no-gutters mb-5">
            {Object.keys(Zones.content).map( key => (
              <Zone data={Zones.content[key]}/>
            ))}
          </div>
        </div>

        <Footer 
          facebookUsername={siteMetadata.facebookUsername}
          facebookURL={siteMetadata.facebookURL}
          emailAddress={siteMetadata.emailAddress}
        />
        <Audio />
      </div>
    )
  }
}

export default Layout
