import React from 'react'

const IconPause = ({
    style = {},
    fill = '#ffffff',
    width = '34',
    height = '34',
    viewBox = '0 0 34 34',
  }) => 
    (
        <svg className="iconPlay" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
            <path fill={fill} fill-rule="evenodd" d="M17 1.417c8.606 0 15.583 6.977 15.583 15.583S25.606 32.583 17 32.583 1.417 25.606 1.417 17 8.394 1.417 17 1.417zm0 2.833C9.958 4.25 4.25 9.958 4.25 17S9.958 29.75 17 29.75 29.75 24.042 29.75 17 24.042 4.25 17 4.25zm-1.417 5.667v14.166H12.75V9.917h2.833zm5.667 0v14.166h-2.833V9.917h2.833z"/>
        </svg>
    );

export default IconPause