import PlayButton from 'components/play-button'
import React from 'react'
import './style.scss'


class Player extends React.Component {
  render() {

    return (
      <div className="player pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-3">
                <PlayButton />
            </div>

            <div className="col-12 d-md-none">
                <p className="text-center mb-0">
                    <span className="footerCopyright__text">
                    👉🏻 Antivirus is a NON commercial radio 👈🏻
                    </span>
                </p>
            </div>

          </div>

        </div>
      </div>
    )
  }
}

export default Player
