import { Link } from 'gatsby'
import Logo from 'components/logo'
import PlayButton from 'components/play-button'

import Img from 'gatsby-image'
import React from 'react'
import './style.scss'


class Header extends React.Component {
  render() {

    return (
      <header className="header">
        <div className="container">

          <div className="row justify-content-center align-items-center no-gutters mt-3 mb-4">
              <div className="col-3 col-md-2 text-left text-md-center">
                  <span className="header__hashtag">#stayhome</span>
              </div>
              <div className="col-4 col-md-3 text-center">
                  <Logo />
              </div>
              <div className="col-3 col-md-2 text-right text-md-center">
                  <span className="header__hashtag">#staysafe</span>
              </div>
          </div>

          <div className="row mb-4">
            <div className="col-12">
              <h1 className="motto text-center">A labour of love & love of music</h1>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
