module.exports = {
  siteMetadata: {
    version: '1.0.6',
    title: 'Antivirus Radio',
    description: 'Το online Πατρινό ραδιόφωνο της καραντίνας',
    url: 'http://antivirusradio.live',
    theme_color: '#0d2549',
    image: 'http://antivirusradio.live/img/antivirusradio_og@2x.png',
    siteUrl: 'http://antivirusradio.live/',
    audioURL: 'http://163.172.35.62:1055/;stream.nsv',
    author: 'ckaklamanos',
    twitter: 'ckaklamanos',
    adsense: '',
    facebookUsername: 'antivirusradio',
    facebookURL: 'https://www.facebook.com/Antivirus-Radio-113194013673592/',
    emailAddress: 'info@antivirusradio.live',
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: 'gatsby-plugin-google-tagmanager',
      options: {
        id: 'GTM-NHS8PSJ',
        includeInDevelopment: true,
      },
    },
    {
      resolve: 'gatsby-plugin-prefetch-google-fonts',
      options: {
        fonts: [
          {
            family: 'Inter',
            variants: [400, 600, 700],
            subsets: ['latin', 'greek'],
          },
        ],
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/posts/`,
        name: 'posts',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/images/`,
        name: 'images',
      },
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: 'gatsby-remark-images',
            options: {
              maxWidth: 750,
              linkImagesToOriginal: false,
              wrapperStyle: 'margin-bottom: 1.0725rem;',
            },
          },
          {
            resolve: 'gatsby-remark-responsive-iframe',
            options: {
              wrapperStyle: 'margin-bottom: 1.0725rem',
            },
          },
          'gatsby-remark-prismjs',
          'gatsby-remark-copy-linked-files',
          'gatsby-remark-smartypants',
        ],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Antivirus Radio',
        short_name: 'antivirusradio',
        description: 'Το online Πατρινό ραδιόφωνο της καραντίνας',
        homepage_url: 'https://antivirusradio.live/',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#0d2549',
        display: 'standalone',
        icons: [
          {
            src: '/img/android-chrome-192x192.png',
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: '/img/android-chrome-512x512.png',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-google-analytics',
      options: {
        trackingId: '',
      },
    },
    {
      resolve: 'gatsby-plugin-netlify',
      options: {
        mergeSecurityHeaders: true,
        mergeLinkHeaders: true,
        mergeCachingHeaders: true,
      },
    },
    'gatsby-plugin-catch-links',
    'gatsby-plugin-offline',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-plugin-sharp',
    'gatsby-plugin-sitemap',
    'gatsby-plugin-twitter',
    'gatsby-transformer-sharp',
  ],
}
