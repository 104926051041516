import IconPlay from 'components/icon-play'
import IconPause from 'components/icon-pause'

import React from 'react'
import './style.scss'

class PlayButton extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            playing: false
        }
    }

    handlePlayButtonClick = (e) => {
        e.preventDefault();
        if (!this.state.playing) {
            this.setState({playing: true})
            document.getElementById('audioElement').play();
        } else {
            this.setState({playing: false})
            document.getElementById('audioElement').pause();
        }
    }

    render() {
        return (
            <div className="playButtonContainer">
                <a href="#" className="playButton" onClick={this.handlePlayButtonClick}>
                    {this.state.playing ? <IconPause />: <IconPlay /> }
                    <span className="playButton__text">LISTEN LIVE</span>
                </a>

            </div>
        )
    }
}

export default PlayButton
