import React from 'react'

const IconFacebook = ({
    style = {},
    fill = '#ffffff',
    width = '24',
    height = '24',
    viewBox = '0 0 24 24',
  }) => 
    (
        <svg className="iconFacebook" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
        <defs>
            <path id="prefix__a" d="M5.825 17.28V9.398H8.47l.397-3.072H5.825V4.365c0-.89.247-1.496 1.522-1.496h1.627V.12C8.692.084 7.727 0 6.604 0 4.257 0 2.651 1.432 2.651 4.06v2.266H0v3.072h2.652v7.882h3.173z"/>
        </defs>
        <g fill="none" fill-rule="evenodd" transform="translate(7.68 3.36)">
            <mask id="prefix__b" fill={fill}>
                <use href="#prefix__a"/>
            </mask>
            <use fill={fill} />
            <g fill={fill} mask="url(#prefix__b)">
                <path d="M0 0H24V24H0z" transform="translate(-7.68 -3.36)"/>
            </g>
        </g>
    </svg>

    );

export default IconFacebook