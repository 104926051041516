import Dj from 'components/dj'

import React from 'react'
import './style.scss'

import Djs from "../../../content/djs.json"

const Zone = ({ data }) => {
 
  const { 
    zoneTitle,
    zoneAlias
  } = data

  const DJs = Djs.content

  return (
        <div className="zoneContainer col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" key={zoneAlias}>
            <h3 className="zoneContainer__title text-center text-md-left">
              <span className="zoneContainer__title-text">{zoneTitle}</span>
            </h3>
            {DJs.filter(dj=>dj.djPlayingZone==zoneAlias).map( dj => (
                <Dj 
                  {...dj}
                  key={dj.djAlias} 
                />
            ))}
        </div>
  ) 
}

export default Zone