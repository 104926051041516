import React from 'react'

const IconPlay = ({
    style = {},
    fill = '#ffffff',
    width = '34',
    height = '34',
    viewBox = '0 0 34 34',
  }) => 
    (
        <svg className="iconPlay" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
            <path fill={fill} fill-rule="evenodd" d="M17 1.417c8.606 0 15.583 6.977 15.583 15.583S25.606 32.583 17 32.583 1.417 25.606 1.417 17 8.394 1.417 17 1.417zm0 2.833C9.958 4.25 4.25 9.958 4.25 17S9.958 29.75 17 29.75 29.75 24.042 29.75 17 24.042 4.25 17 4.25zm-3.5 4.465L24.835 15.8c.888.555.888 1.847 0 2.402l-11.333 7.084c-.944.59-2.168-.089-2.168-1.202V9.917c0-1.113 1.224-1.791 2.168-1.202zm.667 3.758v9.054L21.41 17l-7.243-4.527z"/>
        </svg>
    );

export default IconPlay