import React from 'react'
import { siteMetadata } from '../../../gatsby-config'

const Audio = () => (
    <audio
        id="audioElement"
        src={siteMetadata.audioURL}
        preload="none"
        controls="controls">
    </audio>
)

export default Audio;