import NowPlaying from 'components/now-playing'
import moment from 'moment'
import tz from 'moment-timezone'
import React from 'react'
import './style.scss'

class Dj extends React.Component {

  constructor(props) {
    
    super(props);

    this.state = {
        nowPlaying: false
    };

  }

  getDjPlayingTimeStart() {

    const greece = moment().tz('Europe/Athens').set('hour', this.props.djPlayingHourStart).set('minute', 0);
    
    const localTimeZone = moment.tz.guess();

    return greece.clone().tz(localTimeZone);

  }

  getDjPlayingTimeEnd() {

    const greece = moment().tz('Europe/Athens').set('hour', this.props.djPlayingHourEnd).set('minute', 0);

    if (0==this.props.djPlayingHourEnd) {
      greece.add(1, 'd');
    }
    
    const localTimeZone = moment.tz.guess();

    return greece.clone().tz(localTimeZone);

  }

  checkAndUpdateNowPlaying() {

    const now = moment().tz('Europe/Athens');

    let nowPlayingNew = 
      ( now >= this.getDjPlayingTimeStart() && now < this.getDjPlayingTimeEnd() )
      ? true
      : false;


    // check the day of the week
    
    const day = now.day();
    
    if ( this.props.djPlayingDays && this.props.djPlayingDays.length && !this.props.djPlayingDays.includes(day) ) {
      nowPlayingNew = false;
    }

    this.setState( state => ({
      nowPlaying: nowPlayingNew     
    }));

    
  }

  componentDidMount() {

    this.checkAndUpdateNowPlaying();
    this.interval = setInterval(() => this.checkAndUpdateNowPlaying(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render () {

    const {
      djName,
      djTagline,
      djAlias,
      djProfileImageFileName,
      djProfileURL,
      djPlayingHourStart,
      djPlayingHourEnd,
      djPlayingZone,
   } = this.props

  
    let djComponentRender = (

      <div className="djContainer" key={djAlias}>
        <div className="djContainer__djProfileImageURL">
          <img src={'/img/djs/'+djProfileImageFileName} />
        </div>
        <div className="djContainer__djInformation">
          <div className="djContainer__djPlayingTime">
            <span className="djContainer__djPlayingTime__text">
              {this.getDjPlayingTimeStart().format('HH:mm')} - {this.getDjPlayingTimeEnd().format('HH:mm')}
            </span>
            {!this.state.nowPlaying || <NowPlaying />}
          </div>
          <div className="djContainer__djName">
            <span className="djContainer__djName__text">{djName}</span>{djTagline && <span className="djContainer__djTagline__text">{djTagline}</span>}
          </div>
        </div>
      </div>

    )

    return (

      djProfileURL
      ? (<a href={djProfileURL} target="_blank">{djComponentRender}</a>)
      : (<div>{djComponentRender}</div>) 

    )
  }
}

export default Dj
