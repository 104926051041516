import { Link } from 'gatsby'
import IconFacebook from 'components/icon-facebook'
import IconEnvelop from 'components/icon-envelop'
import PlayButton from 'components/play-button'
import React from 'react'
import './style.scss'

const Footer = ({ facebookUsername, facebookURL, emailAddress }) => (
  <footer className="footer">
    <div className="container">
      <div className="row pt-5 mb-4">
        <div className="col-md-2"></div>

        <div className="col-md-8">
          <p className="text-center">
            <em>"Τα τραγούδια των ανθρώπων είναι πιο όμορφα από τους ίδιους. Όποια κι αν είναι η γλώσσα τους, πάντοτε τα τραγούδια τα κατάλαβα."</em>
          </p>
          <p className="text-center">
            Όταν ο Ναζίμ Χικμέτ έγραφε αυτούς τους στίχους, δύσκολα θα
            φανταζόταν πως το 2020, ένας ιός θα ανάγκαζε τους ανθρώπους
            ολόκληρης της γης σε απομόνωση. Ακόμα πιο δύσκολα θα φανταζόταν πως
            η τεχνολογία θα τους έδινε τη δυνατότητα να είναι ταυτόχρονα
            συνδεδεμένοι. Το μόνο που σίγουρα ήξερε είναι πως τα τραγούδια
            τους κάνουν ευτυχισμένους.
          </p>
          <p className="text-center">
            Μια ομάδα Πατρινών μουσικών παραγωγών και djs, ο καθένας από τους οποίους γινόταν ευτυχισμένος με διαφορετικά είδη τραγουδιών, δέθηκε
            και συνδέθηκε online δημιουργώντας το πρώτο antivirus ραδιόφωνο. Το
            ότι η μουσική ενώνει, δεν θα μπορούσε να βρει καλύτερη εφαρμογή.
          </p>
          <p className="text-center">
            Θέλουμε να πούμε ένα μεγάλο ευχαριστώ στον Τζώννυ Ανδριόπουλο που
            έκανε τις συνθέσεις των σποτς, στον Σάκη Μπάστα (
            <a href="http://www.noiseboxstudio.com/" target="_blank">
              <u>Noise Box Studio</u>
            </a>
            ) για τις εκφωνήσεις, στον Νικόλα Γιατράκο για την τεχνική
            υποστήριξη, στον Αλέξη Σακελλαρόπουλο (<a href="https://connections.gr" target="_blank">
              <u>connections.gr</u>
            </a>
            ) για την δημιουργία του λογοτύπου, στον Κώστα Αλεβιζόπουλο (
            <a href="https://kalev.gr/" target="_blank">
              <u>kalev.gr</u>
            </a>
            ) για την σχεδίαση και στον Χάρη Κακλαμάνο (
            <a href="https://yesinternet.gr/" target="_blank">
              <u>yesinternet.gr</u>
            </a>
            ) για την ανάπτυξη του website antivirusradio.live .
          </p>
        </div>

        <div className="col-md-2"></div>
      </div>

      <div className="row mb-4 no-gutters">
        <div className="col-12 col-md-6">
          <p className="text-left text-md-right">
            <a
              href={facebookURL}
              target="_blank"
              className="footerLink footerLink--first"
            >
              <span className="footerIconContainer text-center">
                <IconFacebook />
              </span>{' '}
              <span className="footerLink__text">{facebookUsername}</span>
            </a>
          </p>
        </div>
        <div className="col-12 col-md-6">
          <p className="text-left">
            <a
              href={'mailto:' + emailAddress}
              target="_blank"
              className="footerLink"
            >
              <span className="footerIconContainer text-center">
                <IconEnvelop />
              </span>{' '}
              <span className="footerLink__text">{emailAddress}</span>
            </a>
          </p>
        </div>
      </div>

      <div className="row d-none d-md-block">
        <div className="col">
          <p className="text-center footerCopyright">
            <span className="footerCopyright__text">
                Antivirus is a NON commercial radio
            </span>
          </p>
        </div>
      </div>

      <div className="row d-md-none pt-5 pb-5"></div>
    </div>
  </footer>
)

export default Footer
